<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Display Name"
          class="mt-5"
        />
        <v-text-field
          v-model="form.key"
          label="Unique Key (e.g. mother-health, for slug & program matching)"
          outlined
          :rules="[required]"
        ></v-text-field>
        <v-text-field
          v-model="form.code"
          label="Unique Internal Code (e.g. BTS-SKN)"
          outlined
          :rules="[required]"
        ></v-text-field>
        <v-select
          v-model="form.type"
          label="Category Type (you can specify nature or just mix them when using)"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>
        <v-select
          v-model="form.parent_id"
          label="Parent Category"
          :items="parentCategoriesOptions"
          clearable
          item-text="title"
          item-value="value"
          outlined
        ></v-select>
        <div class="demo-space-x">
          <p>Intent to display it in a single-level cat system (e.g. PwC, which is not multi-level system)?</p>
          <v-switch
            v-model="form.opt_one"
            color="primary"
            :label="form.opt_one ? 'Show in single-level system' : `Don't show`"
            :disabled="disabledFields.indexOf('active') >= 0"
          ></v-switch>
        </div>
        <v-select
          v-model="form.distributor_id"
          label="Assigned Distributor"
          :items="distributorOptions"
          clearable
          item-text="label"
          item-value="value"
          outlined
        ></v-select>
        <p>If you assign a distributor, only their users can use it.</p>
        <v-text-field
          v-model="form.sort"
          label="Sort Asc order"
          outlined
        ></v-text-field>
        <div class="demo-space-x">
          <v-switch
            v-model="form.active"
            color="primary"
            :label="form.active ? 'Active' : 'Inactive'"
            :disabled="disabledFields.indexOf('active') >= 0"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import {
useAlphaDash,
useDistributor,
useSlugify, useSortTitle, useTranslatable,
} from '@/composables'
import commonStore from '@/modules/common/store'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const typeOptions = [
      { title: 'Service Category', value: 'service' },
      { title: 'Tangible Category', value: 'tangible' },
      { title: 'Voucher Category', value: 'voucher' },
      { title: 'Plan Category', value: 'plan' },
      { title: 'Topup Category', value: 'topup' },
      { title: 'Activity Category', value: 'activity' },
    ]

    const parentCategoriesOptions = commonStore.state.categories.map(cat => ({ title: cat.name.en, value: cat.id })).sort(useSortTitle)

    const { distributorOptions, fetchDistributors } = useDistributor()
    fetchDistributors({ take: 999 })

    const initialForm = {
      name: useTranslatable(props.resource, 'name'),
      parent_id: props.resource ? props.resource.parent_id : null,
      active: props.resource ? props.resource.active : false,
      opt_one: props.resource ? props.resource.opt_one : false,
      key: props.resource ? props.resource.key : '',
      code: props.resource ? props.resource.code : '',
      distributor_id: props.resource ? props.resource.distributor_id : null,
      sort: props.resource ? props.resource.sort : '',
      type: props.resource ? props.resource.type : 'tangible',
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      form.value.key = useAlphaDash(useSlugify(form.value.key))
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      typeOptions,
      parentCategoriesOptions,
      distributorOptions,

      validate,
      required,
      between,
    }
  },
}
</script>
